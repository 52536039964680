import Message from "../Message";
import Drawer from "../common/Drawer";
import { initMelchiorInput } from "../common/init-melchior-input";
import { initPhoneValidation } from "../phone_validation";
import { ajax } from "../utils";
import Modal from "./Modal";
import SlotProposal from "./SlotProposal";

class VisitorProposeSlots {
  /**
   * Initializes the drawer.
   * Sets up form inputs and initializes the Flatpickr library.
   */
  constructor(){
    this.wrapper = document.getElementById('propose-slots-block');
    if(this.wrapper){
      //init the form inputs style
      initMelchiorInput(this.wrapper);

      //at page load, the right part (form) is not visible
      //it only gets visible when the user chooses a the date
      this.form = this.wrapper.querySelector('form');
      if(this.form){
        this.langs = this.wrapper.querySelectorAll('.langs');
        this.errorNotice = this.wrapper.querySelector('.error-notice');
        this.submitButton = this.wrapper.querySelector('button[type=submit]');

        // this.initLangs();
        this.initForm();

        initPhoneValidation();
      }

      //all logic concerning the slots proposal
      const slotProposalContainer = this.wrapper.querySelector('.visitor-suggestions-container');
      if(slotProposalContainer){
        new SlotProposal(slotProposalContainer);
      }
    }
  }

  /**
   * Initializes the form and sets up an observer to listen for attribute changes.
   * When the class attribute contains the * 'recaptcha-done' and 'was-validated' classes, it retrieves the form
   * data, sends an AJAX request to the form action URL, and handles the response.
   */
  initForm(){
    var observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === "class") {
          if(mutation.target.classList.contains('recaptcha-done') && mutation.target.classList.contains('was-validated')){
            const formData = new FormData(this.form);
            ajax(this.form.action, (res) => {
              const {success, message} = res;

              if(success){
                //close the drawer containing the form
                Drawer.closeDrawer(this.wrapper.closest('.drawer'));

                //then show the modal with the success message
                Modal.toggleModal("#suggestSlotsQuoteSuccessModal");
              }
              else {
                Message.error(message);
              }

              //reset the form by removing recaptcha done and was validated classes
              this.resetForm();
            }, 'POST', formData, {}, true, true);
          }
        }
      });
    });

    observer.observe(this.form, {
      attributes: true
    });

    this.form.addEventListener('submit', (e) => {
      e.preventDefault();
    });
  }

  resetForm(){
    this.form.reset();
    this.form.classList.remove('was-validated', 'recaptcha-done');

    //remove the recaptcha hidden field
    const recaptchaHiddenField = this.form.querySelector('[name="g-recaptcha-response"]');
    if(recaptchaHiddenField) recaptchaHiddenField.remove();

    //reset the submit button
    this.submitButton.disabled = false;
    this.submitButton.classList.remove('loading');
  }

  // initLangs(){
  //   if(this.langs){
  //     this.langs.forEach(lang => {
  //       lang.addEventListener('change', () => {
  //         this.errorNotice?.classList.add('hidden');
  //       });
  //     });

  //     //if no langs selected, show the error message
  //     this.submitButton.addEventListener('click', () => {
  //       if(this.wrapper.querySelectorAll('.langs:checked').length === 0){
  //         this.errorNotice?.classList.remove('hidden');
  //       }
  //     });
  //   }
  // }
}

export default VisitorProposeSlots;
