import { initInputNumber } from "../common/input-number";
import {ajax} from "./../utils";
import BookingProcess from "./BookingProcess";
import Drawer from "../common/Drawer";
import { initOffuscation } from "./link-offuscation";
import { initSwiper } from "../common/init-swiper";
import Wish from "./Wish";
import Modal from "./Modal";
import { initCopyLinkToClipboard } from "../common/init-copy-link-to-clipboard";
import tippy from "tippy.js";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { initFormValidation } from "../common/form-validation";
import VisitorProposeSlots from "./VisitorProposeSlots";
import { initDrops } from "../common/drop";

//Drawer that loads experience content & booking form to book directly on the host page
class BookDrawer {
  constructor() {
    //get the drawer that will hold the booking processes
    this.drawer = document.querySelector(".book-experience-drawer");
    if(!this.drawer) return;

    //bind buttons to trigger the ajax request to get the experience book process inside the drawer
    this.drawerBookButtons = document.querySelectorAll(".drawer-book-button:not(.drawer-book-binded)");
    if(this.drawerBookButtons){
      Drawer.bindOpenButtons();

      this.initBookButtons();
    }
  }

  initBookButtons(){
    this.drawerBookButtons.forEach(button => {
      button.classList.add('drawer-book-binded');

      button.addEventListener('click', () => {
        //in case the booking for this experience is already loaded in the drawer, do nothing
        if(button.dataset.experienceId === this.drawer.dataset.currentExperienceId){
          return;
        }

        this.drawer.innerHTML = '';
        this.drawer.classList.add('loading-spinner');

        //trigger an ajax request to get the experience book process inside the drawer
        ajax(button.dataset.url, (html) => {
          this.drawer.innerHTML = html;

          Drawer.init(() => {
            //initialize swiper for the experience pictures
            initSwiper(this.drawer);

            //initialize the inputs number (adults, children, infants);
            initInputNumber();

            //init offuscation for gift card button
            initOffuscation();

            //bind the wish button
            Wish.bindWishButtons(this.drawer);

            // bind any dropdown/up (could be group pricing details for ex)
            initDrops();

            //bind the share button
            Modal.bindAllModals();

            // Copy link to share Host
            initCopyLinkToClipboard();

            //init tippy tooltips
            tippy('[data-tippy-content]', {
              theme: 'light'
            });

            //bind the close button
            // //TODO > add this directly to Drawer class
            const closeDrawer = this.drawer.querySelector('.closeDrawer');
            if(closeDrawer){
              closeDrawer.addEventListener('click', () => {
                Drawer.closeDrawer(this.drawer.parentElement);
              });
            }

            //initialize the booking process and stuff
            new BookingProcess(this.drawer);

            //init the suggests slots form in case it's needed
            new VisitorProposeSlots();

            initFormValidation();
          });


          const btnLivePrice = this.drawer.querySelector('button[data-drawer-tag="live-price"]');
          const livePriceDrawer = document.querySelector('x-drawer[data-button-tag="live-price"] .drawer');
          if(livePriceDrawer && btnLivePrice){
            Drawer.bindOpenButton(btnLivePrice, livePriceDrawer);
          }

          this.drawer.classList.remove('loading-spinner');
          this.drawer.dataset.currentExperienceId = button.dataset.experienceId;
        }, 'GET', {}, {}, true, false);
      });
    });
  }
}

export default BookDrawer;
