import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";
import { ajax, dateToString, debounced, formatCurrency, smoothScrollTo } from "../../../utils";
import { initMelchiorSlider } from "../../../common/init-melchior-slider";
import { initOffuscation } from "../../link-offuscation";
import Wish from "../../Wish";
import BookDrawer from "../../BookDrawer";

class SearchExperience {
  constructor() {
      this.wrapper = document.querySelector('#search-experiences-wrapper');
      if(this.wrapper) {
        this.experiencesList = document.querySelector('#search-experiences-list');
        if(this.experiencesList){
          //init filter object params
          this.initFilterParams();

          //init date filter with flatpickr
          this.initDatepickr();

          //init price slider in the filters bar
          this.initPriceSlider();

          //init persons filter in the filters bar
          this.initPersonsActiveFilter();

          //init langs filter in the filters bar
          this.initLangsActiveFilter();

          //init apply buttons in the filters bar
          this.initApplyButtons();

          //init show more button
          this.initShowMoreButton();

          this.firstSearchDone = false;
          //debounced search for perf
          this.debouncedPerformSearch = debounced(500, () => {
            this.performSearch();
          });

          //perform a search at page load
          this.debouncedPerformSearch();

          //trigger a search when the filter dropdown is left
          this.triggerSearchOnFilterDropdownLeave();

          //init reset filters button
          this.initResetFiltersButton();
        }
      }
  }

  /**
  * Initializes the filter params
  */
  initFilterParams(){
    this.filters = {
      dateFrom: null,
      dateTo: null,
      price: {
        min: null,
        max: null
      },
      persons: {
        adults: 0,
        children: 0
      },
      langs: [],
      around: {
        lat: this.wrapper.dataset.aroundLat ?? null,
        lng: this.wrapper.dataset.aroundLng ?? null,
        radius: this.wrapper.dataset.aroundRadiusMeters
      }
    };
  }

  /**
  * Initializes the date filter
  */
  initDatepickr(){
    this.inlineDate = this.wrapper.querySelector("#date-filter-button");
    this.flatpickrInitedAtClick = false;

    //init the date filter when the date filter button is clicked (otherwise, there a strange bug breaking the flatpickr css)
    this.inlineDate.addEventListener("click", () => {
      if(!this.flatpickrInitedAtClick){
        this.initFlatpickrInstance();
        this.flatpickrInitedAtClick = true;
      }
    });
  }

  /**
  * Initializes the price slider
  */
  initPriceSlider(){
    //init input type range logic (min/max)
    initMelchiorSlider();

    this.priceRangeContainer = this.wrapper.querySelector('#price-slider-wrapper .range_container');
    this.minPriceInput = this.wrapper.querySelector('#price-slider-wrapper .fromInput');
    this.maxPriceInput = this.wrapper.querySelector('#price-slider-wrapper .toInput');

    this.minPrice = this.wrapper.hasAttribute('data-min-price') ? this.wrapper.dataset.minPrice : 0;
    this.maxPrice = this.wrapper.hasAttribute('data-max-price') ? this.wrapper.dataset.maxPrice : this.wrapper.dataset.highestPrice; //max price for the price slider filter
    this.initPriceActiveFilter();
  }

  initPriceActiveFilter(){
    //all currency infos needed to build prices on list/popups
    this.currencyInfos = {
      locale: document.body.dataset.currencyLocale !== '' ? document.body.dataset.currencyLocale.replace("_", "-") : 'en-US',
      rate: document.body.dataset.currencyRate,
      iso: document.body.dataset.currencyIso
    };

    if (this.priceRangeContainer) {
      this.inlinePriceDisplay = this.wrapper.querySelector("#inline-price-text");
      this.priceRangeContainer.addEventListener("sliderValueChanged", (e) => {
        this.minPrice = e.detail.fromValue;
        this.maxPrice = e.detail.toValue;

        if (this.inlinePriceDisplay) {
          let formattedMinPrice = formatCurrency(this.minPrice, this.currencyInfos.locale, this.currencyInfos.iso);
          let formattedMaxPrice = formatCurrency(this.maxPrice, this.currencyInfos.locale, this.currencyInfos.iso);
          this.inlinePriceDisplay.textContent = formattedMinPrice + " • " + formattedMaxPrice;
          this.inlinePriceDisplay.classList.add("active");
        }

        //update price filter
        this.filters.price = {
          min: this.minPrice,
          max: this.maxPrice
        };
      });

      //init update price filter
      this.filters.price = {
        min: this.minPrice,
        max: this.maxPrice
      };

      if (this.inlinePriceDisplay && (this.minPrice != 0 || this.maxPrice != this.wrapper.dataset.highestPrice)) {
        let formattedMinPrice = formatCurrency(this.minPrice, this.currencyInfos.locale, this.currencyInfos.iso);
        let formattedMaxPrice = formatCurrency(this.maxPrice, this.currencyInfos.locale, this.currencyInfos.iso);
        this.inlinePriceDisplay.textContent = formattedMinPrice + " • " + formattedMaxPrice;
        this.inlinePriceDisplay.classList.add("active");
      }
    }
  }

  /**
  * Clears the price filter
  */
  clearPrice(){
    this.maxPrice = this.wrapper.dataset.highestPrice;
    this.minPrice = 0;
    this.priceRangeContainer.dispatchEvent(new Event('reset'));

    if(this.inlinePriceDisplay){
      this.inlinePriceDisplay.textContent = this.inlinePriceDisplay.dataset.initialText;
      this.inlinePriceDisplay.classList.remove('active');
    }

    //update price filter
    this.filters.price = {
      min: this.minPrice,
      max: this.maxPrice
    };
  }

  //handle active text on filters
  initPersonsActiveFilter(){
    this.adults = 0;
    this.children = 0;
    this.adultsInput = this.wrapper.querySelector('#number-adults');
    this.childrenInput = this.wrapper.querySelector('#number-children');

    this.personsText = this.wrapper.querySelector('#nb-persons-text');
    this.personsInput = this.wrapper.querySelectorAll('.personsInput');
    if(this.personsInput){
      this.personsInput.forEach(input => {
        input.addEventListener('change', () => {
          if(input.dataset.type == 'adult') this.adults = input.value;
          else if(input.dataset.type == 'child') this.children = input.value;

          if(this.personsText){
            let text = "";
            if (this.adultsInput.value > 0) {
              let adultsTrad = this.personsText.dataset.adults.replace("{0}", this.adultsInput.value);
              let adultTrad = this.personsText.dataset.adult;
              text += this.adultsInput.value > 1 ? adultsTrad : adultTrad;
            }
            if (this.childrenInput.value > 0) {
              if (this.adultsInput.value > 0) {
                text += ", ";
              }
              let childrenTrad = this.personsText.dataset.children.replace("{0}", this.childrenInput.value);
              let childTrad = this.personsText.dataset.child;
              text += this.childrenInput.value > 1 ? childrenTrad : childTrad;
            }

            if (this.adultsInput.value == 0 && this.childrenInput.value == 0) {
              text = this.personsText.dataset.noValue;
              this.personsText.classList.remove("active");
            }
            else {
              this.personsText.classList.add("active");
            }

            this.personsText.textContent = text;
          }

          //update persons filter
          this.filters.persons = {
            adults: this.adults,
            children: this.children
          };
        });

        input.dispatchEvent(new Event('change'));
      });
    }
  }

  /**
  * Initializes the languages active filter
  */
  initLangsActiveFilter(){
    this.langCheckboxes = this.wrapper.querySelectorAll('.lang-filter');
    this.langFilterText = this.wrapper.querySelector('#lang-active-text');
    this.filters.langs = [];
    if(this.langCheckboxes && this.langFilterText){
      this.langCheckboxes.forEach(checkbox => {
        checkbox.addEventListener('change', () => {
          //update langs filter
          this.filters.langs = [];
          this.langCheckboxes.forEach(checkbox => {
            if(checkbox.checked) this.filters.langs.push(checkbox.dataset.id);
          });

          this.handleLangsActiveText();
        });
      });

      this.handleLangsActiveText();
      this.initClearLanguagesButton();
    }
  }

  /**
  * Handles the active text for the languages filter
  */
  handleLangsActiveText(){
    if(this.filters.langs.length > 0){
      this.langFilterText.textContent = this.langFilterText.dataset.initialText + ' • ' + this.filters.langs.length;
      this.langFilterText.classList.add('active');
    }
    else{
      this.langFilterText.textContent = this.langFilterText.dataset.initialText;
      this.langFilterText.classList.remove('active');
    }
  }

  // Initializes the clear languages button feature
  initClearLanguagesButton(){
    this.clearLanguagesButton = this.wrapper.querySelector('#clear-languages');
    if(this.clearLanguagesButton){
      // Adds an event listener to the button for click events
      this.clearLanguagesButton.addEventListener('click', () => {
        this.clearLanguagesFilter();
      });
    }
  }

  /**
  * Clears the languages filter
  */
  clearLanguagesFilter(){
    // Resets the languages filter array
    this.filters.langs = [];

    // Unchecks all language checkboxes
    this.langCheckboxes.forEach(checkbox => checkbox.checked = false);

    // Removes the active class from the language filter text and sets it to its initial text
    this.langFilterText.classList.remove('active');
    this.langFilterText.textContent = this.langFilterText.dataset.initialText;

    // Finds the closest active dropdown containing the clear languages button
    const dropdown = this.clearLanguagesButton.closest('.dropdown.active');

    // Sets the filter dropdown to inactive
    if(dropdown) dropdown.classList.remove('active');
  }

  /**
  * Performs a search for experiences
  */
  performSearch() {
    if(!this.searchTriggeredFromShowMore){
      this.page = 1;
      this.showMoreButton.dataset.page = 2;
    }

    //get active filters to apply on experiences
    if (this.forceSearch || this.searchTriggeredFromShowMore || !this.previousFilters || (JSON.stringify(this.filters) !== JSON.stringify(this.previousFilters))) {
      this.previousFilters = JSON.parse(JSON.stringify(this.filters));

      //put the overlay on indicating a search is ongoing
      this.experiencesList.classList.add('loading-spinner');

      //get filters as GET parameters
      let currentFilters = this.formatFiltersForSearch();
      currentFilters += '&page=' + this.page;

      //also add the country id for the other wine regions card
      if(this.wrapper.dataset.countryId) currentFilters += '&country_id=' + this.wrapper.dataset.countryId;

      //add experience ids if they exist
      if(this.wrapper.dataset.experienceIds) {
        const experienceIds = JSON.parse(this.wrapper.dataset.experienceIds);
        experienceIds.forEach(experienceId => {
          currentFilters += '&experiences_id[]=' + experienceId;
        });
      }
      if(this.wrapper.dataset.hostIds) {
        const hostIds = JSON.parse(this.wrapper.dataset.hostIds);
        hostIds.forEach(hostId => {
          currentFilters += '&hosts_id[]=' + hostId;
        });
      }
      if(this.wrapper.dataset.regionIds) {
        const regionIds = JSON.parse(this.wrapper.dataset.regionIds);
        regionIds.forEach(regionId => {
          currentFilters += '&regions_id[]=' + regionId;
        });
      }

      //do not include the "prefer another region card" if any of those restrictions are set
      if(this.wrapper.dataset.experienceIds || this.wrapper.dataset.hostIds || this.wrapper.dataset.regionIds){
        currentFilters += '&hide_prefer_another_region_card=true';
      }

      //get the current scroll position
      const scrollPosition = window.scrollY;

      //search experiences in the backend
      ajax(this.wrapper.dataset.searchUrl + '?' + currentFilters, (experiencesHitsHTML) => {
        let oldResultsCount = this.experiencesList.querySelectorAll('.experience-item').length;

        // if page > 1, it means we are showing more experiences through the "show more" button
        if (this.page > 1) {
          // Create a temporary container to parse the HTML
          const tempContainer = document.createElement('div');
          tempContainer.innerHTML = experiencesHitsHTML;

          // Append each child node to the experiences list
          while (tempContainer.firstChild) {
            this.experiencesList.appendChild(tempContainer.firstChild);
          }

          //scroll to the previous position to keep the user in the same place
          window.scrollTo(0, scrollPosition);

          this.showMoreButton.classList.remove('loading');

          let newResultsCount = this.experiencesList.querySelectorAll('.experience-item').length;
          //disable the button if no experiences are found with this call (because we have no more experiences to show)
          this.showMoreButton.disabled = newResultsCount == oldResultsCount;
          this.noMoreResultsToShowNotice.classList.toggle('hidden', newResultsCount != oldResultsCount);
        }
        //otherwise, it means we are on the first set of results (either at page load or after changing filters)
        else {
          //hide the no more results to show notice since it is a new search
          this.noMoreResultsToShowNotice.classList.add('hidden');

          //replace the current experiences list with the new ones
          this.experiencesList.innerHTML = experiencesHitsHTML;

          //show the show more button if experiences are found
          const noExperienceFound = this.experiencesList.querySelector('#no-experiences-found');
          if(!noExperienceFound){
            this.showMoreButton.classList.remove('hidden');
          }
          //hide the show more button if no experiences are found
          else{
            this.showMoreButton.classList.add('hidden');
          }

          this.showMoreButton.disabled = false;

          //scroll the window to the top of the container
          if(this.firstSearchDone){
            smoothScrollTo(this.wrapper);
          }
        }

        this.firstSearchDone = true;

        //Init wish bind buttons
        Wish.bindWishButtons();

        //init offuscation on links
        initOffuscation(this.experiencesList);

        // Booking form drawer that appears on "Book now" click on the experience cards
        new BookDrawer();

        //remove the overlay
        this.experiencesList.classList.remove('loading-spinner');
      }, 'GET', {}, {}, true, false);

      this.searchTriggeredFromShowMore = false;
      this.forceSearch = false;
    }
  }

  /**
  * Formats the filters as GET parameters to be used in the search url
  */
  formatFiltersForSearch(){
    //format filters as GET parameters to be used in the search url
    let params = new URLSearchParams();
    if(this.filters.dateFrom) params.append('date_from', this.filters.dateFrom);
    if(this.filters.dateTo) params.append('date_to', this.filters.dateTo);
    if(this.filters.price.min) params.append('price_min', this.filters.price.min);
    if(this.filters.price.max) params.append('price_max', this.filters.price.max);
    if(this.filters.persons.adults) params.append('adults', this.filters.persons.adults);
    if(this.filters.persons.children) params.append('children', this.filters.persons.children);
    if(this.filters.langs.length > 0){
      this.filters.langs.forEach(langId => params.append('langs[]', langId));
    }
    if(this.filters.around.lat) params.append('lat', this.filters.around.lat);
    if(this.filters.around.lng) params.append('lng', this.filters.around.lng);
    if(this.filters.around.radius) params.append('radius', this.filters.around.radius);

    //returns something like : date_from=2024-11-20&date_to=2024-11-23&price_min=100&price_max=200&adults=2&children=1&langs[]=1&langs[]=2&lat=48.8566&lng=2.3522&radius=50000
    return params.toString();
  }

  /**
  * Initializes the flatpickr instance
  */
  initFlatpickrInstance(){
    const _this = this;

    flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);
    this.flatpickrInstance = null;
    const flatpickrElt = this.wrapper.querySelector(".flatpickr");

    if(flatpickrElt && this.inlineDate) {
      let now = new Date();
      now.setHours(0, 0, 0);

      this.flatpickrInstance = flatpickr(flatpickrElt, {
        inline: true,
        minDate: now,
        dateFormat: "Y-m-d",
        // defaultDate: defaultDate,
        showMonths: flatpickrElt.dataset.showMonths,
        mode: "range",
        onChange: function (selectedDates, dateStr, instance) {
          if (selectedDates.length && selectedDates[0]) {
            const locale = document.body.dataset.lang.replace("_", "-");
            const options = { month: 'short', day: 'numeric' };
            const dateFromText = new Date(selectedDates[0]).toLocaleDateString(locale, options);

            //display the date text in the UI (ex: 10 jan. - 13 jan.)
            if(_this.inlineDate){
              let dateText = dateFromText;
              if(selectedDates[1] && dateToString(selectedDates[0]) !== dateToString(selectedDates[1])){
                const dateToText = new Date(selectedDates[1]).toLocaleDateString(locale, options);
                dateText = dateFromText + ' - ' + dateToText;
              }

              _this.inlineDate.textContent = dateText;
              _this.inlineDate.classList.add("active");
            }
          }

          //make sure that the user can filter only on 4 days max
          if(selectedDates.length == 1) {
            let threeDaysLater = new Date(
                selectedDates[0].getTime() + 86400000 * 3
            );

            instance.config.minDate = selectedDates[0].toUTCString();
            instance.config.maxDate = threeDaysLater.toUTCString();

            //update date filter in the filters bar (in Y-m-d format)
            _this.filters.dateFrom = dateToString(selectedDates[0]);
            _this.filters.dateTo = null;
          }
          else if (selectedDates.length == 2) {
            instance.config.minDate = now.toUTCString();
            instance.config.maxDate = undefined;

            //update date filter in the filters bar (in Y-m-d format)
            _this.filters.dateFrom = dateToString(selectedDates[0]);
            _this.filters.dateTo = dateToString(selectedDates[1]);
          }
        },
        onReady: function (a, b, f) {
          if (_this.wrapper.hasAttribute('data-date') && _this.wrapper.dataset.date !== 'false'){
            f.setDate(f.config.defaultDate, true);
          }

          //init the clear button
          _this.clearDateButton = _this.wrapper.querySelector('#clear-date');
          if(_this.clearDateButton){
            _this.clearDateButton.addEventListener('click', () => {
              _this.clearDate();
            });
          }
        }
      });
    }
  }

  /**
  * Clears the date filter
  */
  clearDate(){
    //clear the flatpickr instance
    if(this.flatpickrInstance){
      this.flatpickrInstance.clear();

      let now = new Date();
      this.flatpickrInstance.config.minDate = now.toUTCString();
      this.flatpickrInstance.config.maxDate = undefined;
    }

    //reset the date text in the UI
    if(this.inlineDate){
      this.inlineDate.textContent = this.inlineDate.dataset.initialText;
      this.inlineDate.classList.remove("active");
    }

    //reset date filter in the filters bar
    this.filters.dateFrom = null;
    this.filters.dateTo = null;
  }

  /**
  * Initializes the apply buttons
  */
  initApplyButtons(){
    this.applyButtons = this.wrapper.querySelectorAll('.save');
    if(this.applyButtons){
      this.applyButtons.forEach(button => {
        button.addEventListener('click', () => {
          button.closest('.dropdown.active').classList.remove('active');

          //trigger a search (if filters have changed)
          this.debouncedPerformSearch();
        });
      });
    }
  }

  /**
  * Initializes the show more button
  */
  initShowMoreButton(){
    this.noMoreResultsToShowNotice = this.wrapper.querySelector('#no-more-results-to-show-notice');
    this.showMoreButton = this.wrapper.querySelector('#show-more-experiences');
    if(this.showMoreButton && this.noMoreResultsToShowNotice){
      this.showMoreButton.addEventListener('click', () => {
        //increment the page number
        this.page = Number(this.showMoreButton.dataset.page);
        this.searchTriggeredFromShowMore = true;

        this.showMoreButton.classList.add('loading');
        this.showMoreButton.disabled = true;

        //perform a new search to show more experiences
        this.performSearch();

        //update the page number on the button
        this.showMoreButton.dataset.page = this.page + 1;
      });
    }
  }

  /**
  * Triggers search when the filter dropdown is left. (user clicks outside the dropdown)
  */
  triggerSearchOnFilterDropdownLeave(){
    this.filterButtons = this.wrapper.querySelectorAll('.btn-dropdown:not(.sort)');
    if(this.filterButtons){
      var observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.attributeName === "class") {
            if(!mutation.target.classList.contains('active')){
              this.debouncedPerformSearch();
            }
          }
        });
      });

      this.filterButtons.forEach(button => {
        observer.observe(button.parentNode, {
          attributes: true,
          attributeFilter: ['class']
        });
      });
    }
  }

  /**
  * Initializes the reset filters button
  */
  initResetFiltersButton(){
    this.wrapper.addEventListener('click', (e) => {
      if(e.target.classList.contains('reset-filters-btn')){
        //clear date filter
        this.clearDate();

        //clear languages filter
        this.clearLanguagesFilter();

        //clear the price filter
        this.clearPrice();

        //perform a new search to show some results
        this.forceSearch = true;
        this.performSearch();
      }
    });
  }
}

export default SearchExperience;
