import Drawer from "../common/Drawer";

class SlotProposal {
  /**
   * Initializes the drawer.
   * Sets up form inputs and initializes the Flatpickr library.
   */
  constructor(wrapper){
    this.wrapper = wrapper;
    if(this.wrapper){
      //init add/remove slot events
      this.initAddSlot();
      this.initRemoveSlot();

      //handle the logic to make sure From is < To hours always
      this.handleHours();

      // Btn to redirect to more slots
      this.initClickHereToCheck();
    }
  }

  initAddSlot(){
    this.addSlotButton = this.wrapper.querySelector('.add-slot');
    this.currentVisibleSuggestions = 1;
    this.currentMaxVisibleSuggestions = 3;

    if(this.addSlotButton){
      this.addSlotButton.addEventListener('click', () => {
        //show the first hidden suggestion slot found
        const hiddenSuggestion = this.wrapper.querySelector('.suggestion.hidden');
        if(hiddenSuggestion){
          hiddenSuggestion.parentElement.appendChild(hiddenSuggestion); //put the slot at the last place
          hiddenSuggestion.classList.remove('hidden');
          this.currentVisibleSuggestions++;

          //if needed, enable the first remove button
          this.enableFirstRemoveButton();

          //add required to inputs in this suggestion
          this.toggleRequiredInputsInElement(hiddenSuggestion, true);
        }

        if(this.currentVisibleSuggestions === this.currentMaxVisibleSuggestions){
          this.addSlotButton.classList.add('hidden');
        }
      });
    }
  }

  initRemoveSlot(){
    this.removeSlotButtons = this.wrapper.querySelectorAll('.remove-suggestion');
    if(this.removeSlotButtons){
      this.removeSlotButtons.forEach(button => {
        button.addEventListener('click', () => {
          //first, check if there are more than 2 visible suggestions, if not, hide the add slot button
          if(this.currentVisibleSuggestions > 1){
            const currentSuggestion = button.closest('.suggestion');
            if(currentSuggestion){
              currentSuggestion.classList.add('hidden');

              this.currentVisibleSuggestions--;

              //remove required to inputs in this suggestion
              this.toggleRequiredInputsInElement(currentSuggestion, false);

              //check if there's only one suggestion left, if so, disable the first remove button
              if(this.currentVisibleSuggestions === 1){
                this.disableFirstRemoveButton();
              }
            }

            this.addSlotButton.classList.remove('hidden');
          }
        });
      });
    }
  }

  disableFirstRemoveButton(){
    const firstRemoveButton = this.wrapper.querySelector('.suggestion:not(.hidden) .remove-suggestion');
    if(firstRemoveButton){
      firstRemoveButton.disabled = true;
      firstRemoveButton.classList.add('remove-disabled');
    }
  }

  enableFirstRemoveButton(){
    const firstRemoveButton = this.wrapper.querySelector('.suggestion:not(.hidden) .remove-suggestion.remove-disabled');
    if(firstRemoveButton){
      firstRemoveButton.disabled = false;
      firstRemoveButton.classList.remove('remove-disabled');
    }
  }

  toggleRequiredInputsInElement(element, required){
    const inputs = element.querySelectorAll('input, select');
    if(inputs){
      inputs.forEach(input => {
        input.required = required;
        input.disabled = !required;
      });
    }
  }

  handleHours(){
    //when from change, disable/remove options in to select that are < from
    //and select the first not disabled option for the "To" select
    const suggestionsSlots = this.wrapper.querySelectorAll('.suggestion');
    suggestionsSlots.forEach(suggestion => {
      const fromHour = suggestion.querySelector('.from-hour');
      const toHour = suggestion.querySelector('.to-hour');
      if(fromHour && toHour){
        const toHourOptions = toHour.querySelectorAll('option');

        fromHour.addEventListener('change', () => {
          //first, reset toHour options > from value
          toHourOptions.forEach(option => {
            if(option.value > fromHour.value){
              option.disabled = false;
              option.classList.remove('hidden');
            }
          });

          //loop through all toHour options and disable/remove them
          let firstOptionFoundSelected = false;
          toHourOptions.forEach(option => {
            if(option.value <= fromHour.value){
              option.disabled = true;
              option.classList.add('hidden');
            }
            else{
              option.classList.remove('hidden');
              option.disabled = false;

              if(fromHour.value >= toHour.value && !firstOptionFoundSelected){
                option.selected = true;
                firstOptionFoundSelected = true;
              }
            }
          });
        });
      }
    });
  }

  initClickHereToCheck(){
    document.querySelector('#click-here-to-check')?.addEventListener('click', () => {
      Drawer.closeDrawer(document.querySelector('.propose-slots-drawer'));
      Drawer.openDrawer(document.querySelector('.more-slots-drawer'));

      document.querySelector('.more-slots-drawer').scrollTo(0, 0);
    });
  }
}

export default SlotProposal;
